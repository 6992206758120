.notes-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
}

.notes-header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
}

.notes-header-button {
  margin: 10px;
}

.notes-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.publicnotes-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.one-note {
  background-color: #fdfd96;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 20px auto;
  width: 300px;
  max-width: 100%;
  font-family: "Comic Sans MS", cursive, sans-serif;
  color: #333;
}

.one-note:nth-child(2n) {
  animation-name: slideInFromRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.one-note:nth-child(2n -1) {
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.one-note p {
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  line-height: 1.6;
  margin: 10px 0;
}

.one-note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.one-note-header p {
  margin: 0;
  font-weight: bold;
}

.one-note-creator {
  font-size: 18px;
  font-style: italic;
  color: #666;
  margin-bottom: 20px;
  text-align: right;
}

.one-note p {
  margin: 10px 0;
  line-height: 1.6;
}

.delEditButton {
  margin: 0 5px 5px 0;
}

@media (min-width: 600px) {
  .one-note {
    width: 460px;
  }
  .notes-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .publicnotes-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
}

.empty-notes {
  color: var(--text-color);
  font-size: 32px;
  display: flex;
  align-items: center;
  min-height: 80vh;
  text-align: center;
}
