.cancel-button {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;
}

.cancel-button:hover::before {
  transform: scaleX(1);
}

.cancel-button-content {
  position: relative;
  z-index: 1;
}

.cancel-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    rgb(233, 93, 93) 10.8%,
    rgb(238, 88, 88) 94.3%
  );
  transition: all 0.475s;
}
