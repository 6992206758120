.publicnote-page-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 140px);
  padding: 20px;
}

.one-publicnote-wrapper {
  width: fit-content;
}

.one-publicnote-container {
  margin: auto;
  background-color: #fdfd96;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  font-family: "Arial", sans-serif;
  color: #333;
  border: 1px solid #e0e0e0;
}

.one-publicnote-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.one-publicnote-by {
  font-size: 18px;
  font-style: italic;
  color: #666;
  margin-bottom: 20px;
  text-align: right;
}

.one-publicnote-content {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 15px;
  white-space: pre-line;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
