.loginbutton {
  background-color: #0a6bff;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset,
    rgba(0, 44, 97, 0.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 15px;
  margin: 20px 0 0 0;
  min-height: 15px;
  min-width: 120px;
  padding: 16px 20px;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
  text-decoration: none;
}

.loginbutton:hover {
  background-color: #065dd8;
  transform: translateY(-2px);
}
