.myprofile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  font-size: 24px;
  word-wrap: break-word;
  padding: 20px;
  width: 100%;
}

.myprofile-div {
  width: 500px;
  color: var(--text-color);
}

.myprofile-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

.myprofile-row label {
  width: 200px;
  margin-right: 10px;
}

.myprofile-row p {
  width: 500px;
}

.myprofile-div {
  color: var(--text-color) !important;
}

.edit-my-profile-div {
  color: var(--bg-color) !important;
}

.header-div {
  display: flex;
  color: var(--text-color);
  font-size: 24px;
  word-wrap: break-word;
  width: 100%;
  justify-content: center;
}

.editIcon {
  max-height: 70px;
  margin: 15px;
}

.myprofile-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 10px 0px;
}

.active-div-myprofile,
.inactive-div-myprofile {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.active-div-myprofile {
  background-color: #1890ff;
  color: #fff;
  font-weight: bold;
}

.inactive-div-myprofile {
  background-color: #f0f0f0;
  color: #000;
  font-weight: normal;
}

.inactive-div-myprofile:hover {
  background: linear-gradient(
    82.3deg,
    rgba(149, 93, 233, 0.678) 10.8%,
    rgba(98, 88, 238, 0.315) 94.3%
  );
}

.message-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myprofile-div-container {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  font-size: 24px;
  word-wrap: break-word;
  width: 100%;
}

.unfollow-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.follow-cards {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  gap: 20px;
}

.follower-card {
  background-color: white;
  color: #4d4d4d;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 200px;
}

.follower-card-profilepic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}
